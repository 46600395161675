<template>
    <div class="box-container">
        <div class="box-header">
            <h3>{{ props.winners ? "Dagens vinnare" : "Dagens förlorare" }}</h3>
            <div
                class="rectangle-button-container"
                id="desktop-button-container"
            >
                <button
                    class="rectangle-button"
                    v-for="elem in listButtons"
                    :key="elem"
                    :id="elem.id"
                    @click="changeList(elem)"
                    :disabled="elem.selected"
                >
                    {{ elem.text }}
                </button>
            </div>

            <div
                class="mobile-button-container"
                :class="showOptions ? ' open' : ''"
				v-click-outside="() => (showOptions = false)"
            >
                <div id="selected-div" @click="showOptions = !showOptions">
                    <span> {{ selectedList.text }}</span>
                    <div class="arrow">
                        <svg
                            class="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 9l-7 7-7-7"
                            ></path>
                        </svg>
                    </div>
                </div>
                <div class="mobile-button-options" v-show="showOptions">
                    <button
                        class="mobile-button-option"
                        v-for="elem in listButtons"
                        :key="elem"
                        :id="elem.id"
						:disabled="elem.selected"
                        @click="changeList(elem)"
                    >
                        {{ elem.text }}
                    </button>
                </div>
            </div>
        </div>
        <div :id="sharesId" :class="fondList ? ' fonder' : ''"></div>
        <div class="link-div">
            <a
                class="link"
                :href="
                    winners
                        ? './shares.php?order=winners'
                        : './shares.php?order=losers'
                "
                >{{ winners ? "Till alla vinnare" : "Till alla förlorare" }}</a
            >
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed, reactive } from "vue";
import List from "@/components/List.vue";
import vClickOutside from "click-outside-vue3";
export default {
    components: {
        List,
    },
    props: {
        winners: {
            type: Boolean,
        },
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    setup(props) {
        const showOptions = ref(false);
        const sharesId = props.winners ? "winners" : "losers";
        const order = props.winners ? ["desc", "desc"] : ["asc", "desc"];
        const fondList = ref(false);
        let toplist;
        const listButtons = reactive([
            {
                text: "Large Cap",
                id: props.winners
                    ? "desktop-winners-large-cap"
                    : "desktop-losers-large-cap",
                selected: true,
                insref: 35207,
            },
            {
                text: "Mid Cap",
                id: props.winners
                    ? "desktop-winners-mid-cap"
                    : "desktop-losers-mid-cap",
                selected: false,
                insref: 35208,
            },
            {
                text: "Small Cap",
                id: props.winners
                    ? "desktop-winners-small-cap"
                    : "desktop-losers-small-cap",
                selected: false,
                insref: 35209,
            },
            {
                text: "Fonder",
                id: props.winners
                    ? "desktop-winners-funds"
                    : "desktop-losers-funds",
                selected: false,
                insref: 21844,
            },
        ]);
        const selectedList = computed(() =>
            listButtons.find((elem) => elem.selected === true)
        );

        const changeList = (elem) => {
            let old = listButtons.find((elem) => elem.selected === true);
            old.selected = false;            
            elem.selected = true;
            toplist.settings.list = [elem.insref];
            if (elem.insref == 21844) {
                toplist.settings.instrumenttype = [8];
                toplist.settings.onreadyCallback = () => {
                    fondList.value = true;
                };
            } else {
                toplist.settings.instrumenttype = [4];
                toplist.settings.onreadyCallback = () => {
                    fondList.value = false;
                };
            }
			showOptions.value = false;
            toplist.drawWidget();
        };
        const buildWidgets = () => {
            MillistreamWidgetSettings.language = "sv";
            MillistreamWidgetSettings.token =
                "b8500098-c785-4b4f-bc0a-4a48e7f65950";
            toplist = new Milli_List({
                target: document.getElementById(sharesId),
                list: [35207],
                controlcolumn: 4,
                maxrows: 12,
                stylecolumn: [4],
                num_decimals: 2,
                forcedecimals: true,
                fields: ["name", "symbol", "lastprice", "diff1d", "diff1dprc"],
                link_field: ["insref"],
                orderby: ["diff1dprc", "quantity"],
                order: order,
                // href: "detailed.php?",
                onRowClick: (e) => {
                    document.location.href = "detailed.php?insref=" + e.insref;
                },
                tr_data_insref: true,
            });
        };
        onMounted(() => {
            buildWidgets();
        });

        return {
            listButtons,
            sharesId,
            changeList,            
            selectedList,
            showOptions,        
            props,
            fondList,
        };
    },
};
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";
.link-div {
    padding-top: 1rem;
}
@media (min-width: 781px) {
    .mobile-button-container {
        display: none;
    }
    #winners,
    #losers {
        width: 100%;
        .millistream-list-table {
            width: 100%;
            thead {
                display: none;
            }
            tbody tr {
                display: grid;
                grid-template-columns: 4fr 90px 1fr 1fr 1fr;
                place-items: center;
                padding: 0.7rem 0.5rem;                
                box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);
                cursor: pointer;
                td.millistream-name {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    text-align: left;
                    font-family: Sueca Hd;
                    width: 100%;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    color: #000000;
                }
                td.millistream-symbol {
                    // justify-self: flex-start;
                    // text-overflow: ellipsis;
                    white-space: nowrap;
                    // overflow: hidden;
                    padding: 4px 12px;
                    background: rgba(37, 45, 64, 0.04);
                    border-radius: 2px;
                    color: $labels;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 10px;
                    line-height: 12px;
                }
                td.millistream-diff1dprc {
                    background: rgba(37, 45, 64, 0.04);
                    border-radius: 28px;
                    padding: 6px 0;
                    font-size: 12px;
                    line-height: 14px;
                    width: 70px;
                    text-align: center;
                    font-weight: 600;
                }
                td.millistream-diff1d,
                td.millistream-lastprice {
                    font-family: Sueca Hd;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    color: #000000;
                }
            }
            tr:hover,
            tr.active {
                background-color: $lightgray;
                border-radius: 4px;
                td.millistream-symbol,
                td.millistream-diff1dprc {
                    background: #fffcf8;
                }
            }
        }
        &.fonder {
            .millistream-list-table {
                tbody tr {
                    grid-template-columns: 5fr 1fr 1fr 1fr;
                    .millistream-symbol {
                        display: none;
                    }
                }
            }
        }
    }
}
@media (max-width: 781px) {
    #desktop-button-container {
        display: none;
    }
	.box-container{
		padding:1rem 0;
	}
    #winners,
    #losers {
        width: 100%;
        .millistream-list-table {
            width: 100%;
            thead {
                display: none;
            }
            tbody tr {
                display: grid;
                grid-template-columns: 1fr auto auto;
                place-items: center;
                padding: 0.7rem 0;
                column-gap: 0.5rem;
                box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);
                font-family: Sueca Hd;
                td.millistream-name {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    width: 100%;
                    text-align: left;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 16px;
                    color: #000000;
                    text-decoration: none;
                    border-bottom: thin solid transparent;
                    transition: 300ms;
                }
                td.millistream-symbol {
                    display: none;
                    // justify-self: flex-start;
                    font-family: SuecaSans;
                    padding: 4px 12px;
                    background: rgba(37, 45, 64, 0.04);
                    border-radius: 2px;
                    color: $labels;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 10px;
                    line-height: 12px;
                    text-decoration: none;
                    border-bottom: thin solid transparent;
                    transition: 300ms;
                }
                td.millistream-diff1dprc {
                    background: rgba(37, 45, 64, 0.04);
                    border-radius: 28px;
                    padding: 6px 0;
                    font-size: 12px;
                    line-height: 14px;
                    width: 70px;
                    font-weight: 600;
                }
                td.millistream-lastprice {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 16px;
                    color: #000000;
                }
                td.millistream-diff1d {
                    display: none;
                }
            }
        }
    }
}
</style>


